import { createAction, props } from '@ngrx/store';
import {
  Company,
  CompanyBootstrap,
  CompanyUpdate,
  DataObject,
  ErrorObject,
  ResponseObject,
  StoresUpdate,
  Stores,
  StoreSettingUpdate,
  StoreSetting,
} from '@app/_shared/interfaces';

export const loadCompanyBootstrap = createAction('[CompanyBootstrap] Load Company Bootstraps');
export const loadCompanyBootstrapSuccess = createAction(
  '[CompanyBootstrap] Load Company Bootstraps success',
  props<ResponseObject<CompanyBootstrap>>(),
);
export const loadCompanyBootstrapFailed = createAction('[CompanyBootstrap] Load Company Bootstraps failed');

export const setDefaultStoreById = createAction(
  '[CompanyBootstrap] Set Default Store By Id',
  props<{ storeId: number | null }>(),
);

export const setDefaultStoreIdLocalstorage = createAction(
  '[CompanyBootstrap] Set Default Store Localstorage',
  props<{ storeId: number | null }>(),
);

export const updateStoreName = createAction(
  '[CompanyBootstrap] Update Store Name By Id',
  props<{ storeId: number; name: string }>(),
);

export const updateCompany = createAction('[Company] Update Company', props<{ data: CompanyUpdate }>());
export const updateCompanySuccess = createAction(
  '[Company] Update Company Success',
  props<{ data: DataObject<Company> }>(),
);
export const updateCompanyFailed = createAction('[Company] Update Company Failed', props<ErrorObject>());

export const updateStore = createAction('[Store] Update Store', props<{ id: number; data: StoresUpdate }>());
export const updateStoreSuccess = createAction('[Store] Update Store Success', props<{ data: DataObject<Stores> }>());
export const updateStoreFailed = createAction('[Store] Update Store Failed', props<ErrorObject>());

export const updateStoreSettings = createAction(
  '[StoreSettings] Update Store Setting',
  props<{ storeId: number; data: StoreSettingUpdate }>(),
);

export const updateStoreSettingsSuccess = createAction(
  '[StoreSettings] Update Store Setting Success',
  props<{ data: DataObject<StoreSetting> }>(),
);

export const updateStoreSettingsFailed = createAction(
  '[StoreSettings] Update Store Setting Failed',
  props<ErrorObject>(),
);
